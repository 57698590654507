import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import InventoryDetails from './inventoryDetails.vue';

formCreate.component('InventoryDetails', InventoryDetails);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      progressList: [],
      chooseAct: [],
      formFunctionCode: 'inventory_details',
      formParentCode: 'CRM20230609000000002',
    };
  },

  async created() {
    this.init();
  },

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      return v;
    },

    formComplete() {
      const inventoryDetails = this.getRule('inventoryDetails'); // 支付明细列表
      inventoryDetails.props = {
        ...inventoryDetails.props,
        actCode: this.formConfig.row.actCode,
        code: this.formConfig.code,
      };
      // 活动明细列表
      inventoryDetails.on.change = (e) => {
        if (e) {
          console.log(e);
        }
      };
    },

    // 初始化数据
    async init() {
      const { row } = this.formConfig;
      const data = await request.post('/dms/stock1978/pageDetailList', { infoId: row.id }).then((res) => (res.success ? res.result : []));
      const inventoryDetails = this.getRule('inventoryDetails'); // 活动明细列表
      inventoryDetails.value = data || {};
      this.setValue(data);
    },
  },
};
