<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {

    };
  },
  mounted() {
    this.getConfigList('inventory_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'check_detail') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = '查看明细';
        this.openFull();
      }
    },
  },
};
</script>
