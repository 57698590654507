var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _vm._t("toolbar"),
      _c(
        "el-form",
        [
          _c(
            "vxe-table",
            { ref: "fineTable", attrs: { data: _vm.paymentList } },
            [
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "direction",
                  title: "产品流通方向",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.direction === "1"
                          ? _c("span", [_vm._v("入库")])
                          : _vm._e(),
                        row.direction === "2"
                          ? _c("span", [_vm._v("出库")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "productCode",
                  title: "产品编码",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "productName",
                  title: "产品名称",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "changeNum",
                  title: "出入库数量",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "price",
                  title: "产品单价",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "amount",
                  title: "出入库产品金额",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total,prev, pager, next",
              "current-page": _vm.pagination.current,
              "page-size": _vm.pagination.pageSize,
              total: _vm.pagination.total,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }