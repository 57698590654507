<template>
  <div class="edit-table">
    <slot name="toolbar"></slot>
    <el-form>
      <vxe-table  ref="fineTable" :data="paymentList">
        <vxe-table-column min-width="100" field="direction" title="产品流通方向">
            <template #default="{ row }">
                <span v-if="row.direction==='1'">入库</span>
                <span v-if="row.direction==='2'">出库</span>
            </template>
        </vxe-table-column>
        <vxe-table-column min-width="100" field="productCode" title="产品编码"></vxe-table-column>
        <vxe-table-column min-width="100" field="productName" title="产品名称"></vxe-table-column>
        <vxe-table-column min-width="100" field="changeNum" title="出入库数量"></vxe-table-column>
        <vxe-table-column min-width="100" field="price" title="产品单价"></vxe-table-column>
        <vxe-table-column min-width="100" field="amount" title="出入库产品金额"></vxe-table-column>
      </vxe-table>
    </el-form>
    <div class="pagination">
      <el-pagination background layout="total,prev, pager, next" @current-change="handleCurrentChange" :current-page="pagination.current" :page-size="pagination.pageSize" :total="pagination.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import elementComponents from './elementComponents';

export default {
  name: 'Payment',
  props: {
    value: Object,
  },
  components: {
    ...elementComponents,
  },
  watch: {
    value() {
      this.paymentList = this.value.data;
      this.pagination.total = this.value.count;
    },
  },
  data() {
    return {
      paymentList: [], // 支付明细
      pagination: {
        total: 0,
        pageSize: 20,
        current: 1,
      },
    };
  },

  methods: {
    // change分页
    handleCurrentChange(val) {
      console.log(val);
      this.pagination.current = val;
    },

  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;

}
.form-conrtol {
  width: 100%;
}
/deep/ .vxe-body--column {
  height: 50px !important;
}
.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  color: white !important;
}
</style>
